import routesArray from './routes';
import { AccessPath } from './Guard';
import LoadingCover from '../../Components/LoadingCover';
import { Switch, Route, Redirect } from 'react-router-dom';
import { FC, LazyExoticComponent, Suspense, Fragment } from 'react';
import { AccessType, useUserAccess } from '../../Components/UserAccess';

export type Path = {
	guard?: any;
	exact: boolean;
	path: string[];
	accesses?: AccessType[];
	component: LazyExoticComponent<() => JSX.Element> | (() => JSX.Element) | FC;
};

export type RouteRendererProps = {
	base?: string;
	routes?: Path[];
	accesses?: AccessType[];
	fallback?: () => JSX.Element;
};

const RouteRenderer: FC<RouteRendererProps> = ({
	fallback,
	accesses,
	base = '',
	routes = routesArray,
}) => {
	const { hasAccess } = useUserAccess();
	if (accesses && !hasAccess(...accesses)) return <Redirect to='/dashboard' />;

	return (
		<Suspense fallback={fallback?.() || <LoadingCover />}>
			<Switch>
				{routes.map((route, i) =>
					route.path.map(path => {
						let PathGuard = route.guard || Fragment;
						return (
							<Route key={i} path={`${base}${path}`} exact={route.exact}>
								<PathGuard>
									<AccessPath accesses={route?.accesses || []}>
										<route.component />
									</AccessPath>
								</PathGuard>
							</Route>
						);
					})
				)}
			</Switch>
		</Suspense>
	);
};

export default RouteRenderer;
