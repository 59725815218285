import { authFetch } from '../../Hooks/useFetch';
import { createHashId } from '../../Utils/String';
import { CONFIG } from '../../../App/Config/constants';
import { clearLocalStorage } from './LocalStorageActions';
import { UserAccountType } from '../reducers/AccountReducer';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

export const logout = createAction('logout');

export const setUserToken = createAction<string>('setUserToken');

export const setUser = createAction<UserAccountType>('setUser');

export const login = createAsyncThunk<any, { email: any; password: any }>(
	'login',
	async ({ email, password }, { rejectWithValue, dispatch }) => {
		let data: any;
		const body = { email: email, password: password };
		try {
			data = await authFetch.post('/users/login', { body });
			if (!data || data?.error) return rejectWithValue({ error: data?.error || '' });
			const isEqualPrev = isEqualToPrevUserAccess(data);
			if (!isEqualPrev) await new Promise(resolve => resolve(dispatch(clearLocalStorage())));
		} catch {
			return rejectWithValue({
				error: navigator.onLine ? 'Email and password are invalid' : undefined,
			});
		}
		return { user: data.user };
	}
);

const isEqualToPrevUserAccess = (data: any) => {
	const lsName = `${CONFIG.APP_NAME}-UA`;
	const prevHash = localStorage.getItem(lsName);
	const userData = { access: data?.user?.access, userId: data?.user?.userId };
	const userIdHash = createHashId(JSON.stringify(userData));
	const isEqual = !!prevHash && userIdHash === prevHash;
	if (!isEqual) localStorage.setItem(lsName, userIdHash);
	return isEqual;
};
