import { momentType } from '../../Data/types';

// export const mGetFutureWed = (d: momentType) => {
//     const dayNumber = d.day()
//     const distance = dayNumber > 3 ? 7 - (dayNumber - 3) : 3 - dayNumber;
//     return d.clone().add(distance, "days");
// };

export const mGetLastWed = (d: momentType) => {
    const dayNumber = d?.day?.()
    const distance = (dayNumber >=3) ? (dayNumber - 3) : (4 + dayNumber);
    return d?.clone?.().subtract(distance, "days")
};

export const mGetFutureTue = (d: momentType) => {
    const dayNumber = d?.day?.()
    const distance = dayNumber > 2 ? 7 - (dayNumber - 2) : 2 - dayNumber;
    return d?.clone?.()?.add?.(distance, "days");
};

// export const mGetLastTue = (d: momentType) => {
//     const dayNumber = d.day()
//     const distance = dayNumber >= 2 ? dayNumber - 2 : 5 + dayNumber;
//     return d.clone().subtract(distance, "days");
// };