import { createReducer } from "@reduxjs/toolkit";
import { setRange } from '../actions/DatePickerAction';

type RangeReducerType = {
	startDate: string,
	endDate: string,
}

const initRange: RangeReducerType = {
	startDate: "",
	endDate: "",
	
};

const RangePickerReducer = createReducer(initRange, {
	[setRange.type]: (state, { payload }) => ({
		...state,
		startDate: payload.startDate,
		endDate: payload.endDate,
		
	}),
	
});


export default RangePickerReducer;
