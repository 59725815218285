import { createAction } from '@reduxjs/toolkit';
import { LocationType } from '../reducers/LocalStorageReducer';

export const open = createAction('open');
export const close = createAction('close');
export const toggle = createAction('toggle');
export const setLoading = createAction<boolean>('setLoading');

export const setLocation = createAction<LocationType | undefined>('setLocation');

export const setActiveRequestCount = createAction<number>('setActiveRequestCount');
export const setWeek = createAction<'Active' | 'Past'>('setWeek');
export const setBiWeek = createAction<string | undefined>('setBiWeek');
export const setBaseDate = createAction<string | undefined>('setBaseDate');
