import { combineReducers } from 'redux';
import AccountReducer from './AccountReducer';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import DashboardReducer from './DashboardReducer';
import { CONFIG } from '../../../App/Config/constants';
import LocalStorageReducer from './LocalStorageReducer';
import RangePickerReducer from './RangePickerReducer';
import DatePickerReducer from './DatePickerReducer';
import WeekPickerReducer from './weekPickerReducer';

const reducers = combineReducers({
	account: persistReducer(
		{
			storage,
			key: 'account',
			blacklist: ['error', 'loading'],
			keyPrefix: `${CONFIG.APP_NAME}-`,
		},
		AccountReducer
	),
	dashboard: persistReducer(
		{
			storage,
			key: 'dashboard',
			blacklist: ['isLoading'],
			keyPrefix: `${CONFIG.APP_NAME}-`,
		},
		DashboardReducer
	),
	localStorage: persistReducer(
		{
			storage,
			key: 'localStorage',
			keyPrefix: `${CONFIG.APP_NAME}-`,
		},
		LocalStorageReducer
	),
	dates: DatePickerReducer,
	ranges: RangePickerReducer,
	payrollWeek: WeekPickerReducer,
});

export default reducers;
