import { createReducer } from '@reduxjs/toolkit';
import { removeFaIconCache } from '../../Utils/Cache';
import { login, logout, setUserToken, setUser } from '../actions/AccountActions';

const initState: AccountType = {
	user: {
		name: '',
		token: '',
		email: '',
		userId: '',
		locationId: '',
		access: { isSuper: false },
	},
	loggedIn: false,
	loading: false,
	error: '',
};

const AccountReducer = createReducer(initState, {
	[logout.type]: (state, _) => {
		removeFaIconCache();
		return {
			user: null,
			loading: false,
			loggedIn: false,
			error: '',
		};
	},

	[login.pending.type]: (state, { payload }) => ({
		user: null,
		loading: true,
		loggedIn: false,
		error: '',
	}),
	[login.rejected.type]: (state, { payload }) => ({
		user: null,
		loading: false,
		loggedIn: false,
		error: payload.error,
	}),
	[login.fulfilled.type]: (state, { payload }) => ({
		user: payload.user,
		loggedIn: true,
		loading: false,
		error: '',
	}),

	[setUserToken.type]: (state, { payload }) => ({
		...state,
		user: {
			...state.user!!,
			token: payload,
		},
	}),

	[setUser.type]: (state, { payload }) => ({
		...state,
		user: payload,
	}),
});

export type UserAccountType = {
	name: string;
	token: string;
	email: string;
	userId: string;
	locationId: string;
	access?: {
		isSuper?: boolean;
		locations?: string[];
		app_modules?: string[];
		web_modules?: string[];
	};
} | null;

export type AccountType = {
	user: UserAccountType;
	loggedIn: boolean;
	loading: boolean;
	error: string;
};

export default AccountReducer;
