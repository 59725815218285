import Guard from './Guard';
import { lazy } from 'react';
import { Path } from './RouteRenderer';
import { Redirect } from 'react-router-dom';
import { CONFIG } from '../Config/constants';

export const routesArray: Path[] = [
	{
		exact: true,
		guard: Guard.GuestPath,
		path: ['/', '/login'],
		component: lazy(() => import('../../Views/LoginLayout/Login')),
	},
	{
		exact: true,
		path: ['/reset-password/:token'],
		component: lazy(() => import('../../Views/LoginLayout/ResetPassword/ResetPassword')),
	},
	{
		exact: false,
		guard: Guard.AuthPath,
		path: ['/', '/dashboard'],
		component: lazy(() => import('../../Views/DashboardLayout/DashboardLayout')),
	},
	{
		path: ['*'],
		exact: true,
		component: () => <Redirect to={CONFIG.BASE_URL} />,
	},
];

export default routesArray;
