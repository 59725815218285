import preval from 'preval.macro';
import { useEffect } from 'react';
import useStore from '../Store/useStore';
import { removeFaIconCache } from '../Utils/Cache';
import { logout } from '../Store/actions/AccountActions';

//* Build time
const cur_version = preval`module.exports = new Date().getTime();`;

export const checkForCacheInvalidation = () => {
	let isInvalidate = false;
	const pre_version = localStorage.getItem('BUILD_VERSION');
	if (!pre_version) isInvalidate = true;
	if (pre_version && +pre_version < +cur_version) isInvalidate = true;
	localStorage.setItem('BUILD_VERSION', cur_version);
	return isInvalidate;
};

const useCacheInvalidator = () => {
	const { dispatch } = useStore();

	const onInvalidate = () => {
		dispatch(logout());
		removeFaIconCache();
		localStorage?.clear();
		localStorage?.setItem('BUILD_VERSION', cur_version);
	};

	useEffect(() => {
		const isInvalidated = checkForCacheInvalidation();
		if (!isInvalidated || window.MODE === 'LOCAL') return;
		onInvalidate();
		console.log('✅🔃 Assets cache refreshed successfully!');
	}, []);
};

export default useCacheInvalidator;
