import { createReducer } from "@reduxjs/toolkit";
import { setPayrollWeek } from '../actions/DatePickerAction';
import { mGetLastWed } from "../../Utils/Moment";
import moment from "moment";

type WeekPickerReducerType = {
	startDate: string,
	endDate: string,
}

const initWeek: WeekPickerReducerType = {
	startDate: mGetLastWed(moment.utc()).startOf("day").toString(),
	endDate: mGetLastWed(moment.utc()).add(6,"days").startOf("day").toString()
};


const WeekPickerReducer = createReducer(initWeek, {
	[setPayrollWeek.type]: (state, { payload }) => ({
		...state,
		startDate: payload.startDate,
		endDate: payload.endDate,

	}),

});


export default WeekPickerReducer;
