import { createReducer } from '@reduxjs/toolkit';
import { LocationType } from './LocalStorageReducer';
import {
	open,
	close,
	toggle,
	setWeek,
	setBiWeek,
	setLoading,
	setLocation,
	setBaseDate,
	setActiveRequestCount,
} from '../actions/DashboardActions';

const initState: {
	isOpen: boolean;
	baseDate?: Date;
	biWeek?: string;
	isLoading: boolean;
	week?: 'Active' | 'Past';
	isLocationLocked?: boolean;
	activeRequestsCount?: number;
	currentLocation?: LocationType;
} = {
	isOpen: true,
	week: 'Active',
	isLoading: false,
	biWeek: undefined,
	currentLocation: {},
	baseDate: undefined,
	activeRequestsCount: 0,
	isLocationLocked: false,
};

const DashboardReducer = createReducer(initState, {
	//* sideBar state
	[open.type]: (state, _) => ({
		...state,
		isOpen: true,
	}),
	[close.type]: (state, _) => ({
		...state,
		isOpen: false,
	}),
	[toggle.type]: (state, _) => ({
		...state,
		isOpen: !state.isOpen,
	}),

	//* loading
	[setLoading.type]: (state, { payload }) => ({
		...state,
		isLoading: payload,
	}),

	//* location
	[setLocation.type]: (state, { payload }) => ({
		...state,
		currentLocation: payload,
	}),

	//* select navbar active week
	[setWeek.type]: (state, { payload }) => ({
		...state,
		week: payload,
	}),

	//* active Hr & Payroll Changes count
	[setActiveRequestCount.type]: (state, { payload }) => ({
		...state,
		activeRequestsCount: payload,
	}),

	//* select biWeekly active week
	[setBiWeek.type]: (state, { payload }) => ({
		...state,
		biWeek: payload,
	}),

	//* select biWeekly active week
	[setBaseDate.type]: (state, { payload }) => ({
		...state,
		baseDate: payload,
	}),
});

export default DashboardReducer;
