const BASE = {
	BASE_URL: '/',
	APP_NAME: 'RumisHQv2',
};

const PRODUCTION = {
	SERVER: 'https://back.rumishq.com',
	WSS: 'wss://back.rumishq.com',
	GIFTCARD: {
		BACKEND: 'https://back.giftcard.rumishq.com',
		SHOPIFY_STORE_ORDERS: 'https://admin.shopify.com/store/rumis-kitchen-gift-card-store/orders',
	},
	FORMS: {
		BACKEND: 'https://back.rumis.cc',
	},
};

const DEVELOPMENT = {
	WSS: 'wss://back.dev.rumishq.com',
	SERVER: 'https://back.dev.rumishq.com',
	GIFTCARD: {
		BACKEND: 'https://back.dev.giftcard.rumishq.com',
		SHOPIFY_STORE_ORDERS: 'https://admin.shopify.com/store/rumis-gift-dev/orders',
	},
	FORMS: {
		BACKEND: 'https://back.dev.forms.rumishq.com',
	},
};

const LOCAL = {
	WSS: 'wss://back.dev.rumishq.com',
	SERVER: 'https://back.dev.rumishq.com',
	// SERVER: 'https://back.rumishq.com',
	// SERVER: 'http://127.0.0.1:1337',
	GIFTCARD: {
		BACKEND: 'https://back.dev.giftcard.rumishq.com',
		SHOPIFY_STORE_ORDERS: 'https://admin.shopify.com/store/rumis-gift-dev/orders',
	},
	FORMS: {
		BACKEND: 'https://back.dev.forms.rumishq.com',
	},
};

//? --------------------- Config selection ----------------------------------------

export type CONFIG_TYPE = typeof BASE & typeof PRODUCTION & typeof DEVELOPMENT & typeof LOCAL;

const CONFIG_BY_MODE: CONFIG_TYPE = { ...(({ LOCAL, PRODUCTION, DEVELOPMENT } as any)[window.MODE as any] || {}) };

export const CONFIG = {
	...BASE,
	...CONFIG_BY_MODE,
	API_SERVER: CONFIG_BY_MODE?.SERVER + '/api',
};

window.CONFIG = CONFIG;
