import { FC } from 'react';
import { Redirect } from 'react-router-dom';
import useStore from '../../Tools/Store/useStore';
import useAccount from '../../Tools/Hooks/useAccount';
import { isTokenValid } from '../../Tools/Hooks/useAccount';
import { logout } from '../../Tools/Store/actions/AccountActions';
import { AccessType, useUserAccess } from '../../Components/UserAccess';

export const AuthPath: FC = ({ children }) => {
	const { dispatch } = useStore();
	const { loggedIn, user } = useAccount();
	if (loggedIn && !isTokenValid(user?.token)) dispatch(logout());
	if (!loggedIn) return <Redirect to='/login' />;
	return <>{children}</>;
};

export const GuestPath: FC = ({ children }) => {
	const { dispatch } = useStore();
	const { loggedIn, user } = useAccount();
	if (loggedIn && !isTokenValid(user?.token)) dispatch(logout());
	if (loggedIn) return <Redirect to='/dashboard' />;
	return <>{children}</>;
};

export type AccessPathType = { accesses: AccessType[]; currentPath?: string };
export const AccessPath: FC<AccessPathType> = ({ children, accesses }) => {
	const { hasSomeAccess } = useUserAccess();
	if (accesses.length > 0 && !hasSomeAccess(...accesses)) return <Redirect to='/dashboard' />;
	return <>{children}</>;
};

const Guard = { AuthPath, GuestPath };

export default Guard;
