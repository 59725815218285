import { createReducer } from "@reduxjs/toolkit";
import { getLastWed, wayBackMachine } from "../../Utils/Date";
import { setDate, clearDate } from '../actions/DatePickerAction';

type DateReducerType = {
	startDate: string,
	endDate: string,
	weekConfig: { week1: { startDate: string, endDate: string }, week2: { startDate: string, endDate: string } },
}

const d = new Date(new Date().setUTCHours(0,0,0,0))
const initDates: DateReducerType = {
	startDate: wayBackMachine(getLastWed(d), 7).toUTCString(),
	endDate: wayBackMachine(getLastWed(d), -6).toUTCString(),
	weekConfig: {
		week1: {
			startDate: wayBackMachine(getLastWed(d), 7).toUTCString(),
			endDate: wayBackMachine(getLastWed(d), 1).toUTCString()
		},
		 week2: {
			startDate: getLastWed(d).toUTCString(),
			 endDate: wayBackMachine(getLastWed(d), -6).toUTCString()
		}
	}
};


const DatePickerReducer = createReducer(initDates, {
	[setDate.type]: (state, { payload }) => ({
		...state,
		startDate: payload.startDate,
		endDate: payload.endDate,
		weekConfig: payload.weekConfig,

	}),
	[clearDate.type]: (state, _) => ({
		...state,
		startDate: undefined, endDate: undefined,
	}),
});


export default DatePickerReducer;
