import { createAction } from '@reduxjs/toolkit';

type LocalActionEntryType = { data: [] | object; partialUpdate: boolean; deleted?: string[] };

export const setLocalUsers = createAction<LocalActionEntryType>('setLocalUsers');
export const setLocalLocations = createAction<LocalActionEntryType>('setLocalLocations');

export const setLocalChanges = createAction<LocalActionEntryType>('setLocalChanges');
export const setLocalEmployee = createAction<LocalActionEntryType>('setLocalEmployee');

export const setLocalRecipes = createAction<LocalActionEntryType>('setLocalRecipes');
export const setLocalRecipeCategories = createAction<LocalActionEntryType>('setLocalRecipeCategories');

export const setLocalLineBuilds = createAction<LocalActionEntryType>('setLocalLineBuilds');
export const setLocalLineBuildCategories = createAction<LocalActionEntryType>('setLocalLineBuildCategories');

export const setLocalVendors = createAction<LocalActionEntryType>('setLocalVendors');
export const setLocalSpecbooks = createAction<LocalActionEntryType>('setLocalSpecbooks');
export const setLocalContracts = createAction<LocalActionEntryType>('setLocalContracts');

export const clearLocalStorage = createAction('clearLocalStorage');
