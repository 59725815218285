import useAccount from './useAccount';
import { getToken } from './useAccount';
import { notify } from '../Utils/React';
import { useCallback, useState } from 'react';
import { CONFIG } from '../../App/Config/constants';

type RequestMethods = 'GET' | 'POST' | 'PUT' | 'DELETE';
type RequestOptions = Omit<RequestInit, 'body'> & {
	url?: string;
	base?: boolean;
	token?: string;
	params?: object;
	throwError?: boolean;
	body?: object | string | BodyInit;
};

const ReqInit: RequestOptions = { base: true, throwError: true };

const extendOptions = (options: RequestOptions) => ({ ...ReqInit, ...options });

const authFetch = async (url: string, options?: RequestOptions) => {
	let json: any;

	const token = !!options?.token ? options?.token : getToken();

	let body = options?.body;
	const isFormData = body instanceof FormData;
	if (body && typeof body === 'object' && !isFormData) body = JSON.stringify(body);

	const fetchOptions = extendOptions({ ...options, body });
	fetchOptions.headers = {
		...fetchOptions.headers,
		...(token ? { Authorization: 'Bearer ' + token } : {}),
		...(!isFormData ? { 'Content-type': 'application/json' } : {}),
	};

	//? Handling Query params
	let params: any = fetchOptions?.params;
	let stringParam;
	if (params) {
		let entries: any[] = Object.entries(params || {}).filter(item => item[1] !== undefined);
		entries = entries.map(item => `${item[0]}=${item[1]}`);
		if (!!entries?.length) stringParam = '?' + entries.join('&');
	}

	let requestUrl = (fetchOptions.base ? CONFIG.API_SERVER : '') + url + (stringParam || '');

	if (!navigator.onLine)
		notify('Network not connected', {
			type: 'error',
			duration: 4000,
			icon: 'l-wifi-slash',
		});

	let statusCode: any, rawData: any;
	try {
		if (!navigator.onLine) throw new Error('Network not connected');
		rawData = await fetch(requestUrl, fetchOptions as RequestInit);
		statusCode = rawData?.status;

		try {
			json = await rawData?.json?.();
		} catch (error: any) {
			// throw error
		}

		if (statusCode >= 400) {
			throw new Error(json?.error || `Request Error ${statusCode}`);
		}
	} catch (e) {
		console.warn(e);
		if (options?.throwError) throw e;
	}

	return json;
};

const objectToFormData = (obj: { [key: string]: string | Blob | undefined }) => {
	const formData = new FormData();
	Object.keys(obj).forEach(key => obj[key] !== undefined && formData.append(key, obj[key]!!));
	return formData;
};

const fetchByMethod = (method: RequestMethods) => async (url: string, options?: RequestOptions) =>
	await authFetch(url, { method: method, ...ReqInit, ...options });
authFetch.get = fetchByMethod('GET');
authFetch.put = fetchByMethod('PUT');
authFetch.post = fetchByMethod('POST');
authFetch.delete = fetchByMethod('DELETE');

const useFetch = <T extends object | any = any>(fetchUrl?: string, fetchOptions?: RequestOptions) => {
	const { user } = useAccount();
	const [data, setData] = useState<null | T>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<boolean | any>(false);

	const [getLoading, setGetLoading] = useState<boolean>(false);
	const [putLoading, setPutLoading] = useState<boolean>(false);
	const [postLoading, setPostLoading] = useState<boolean>(false);
	const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

	const setLoadingByMethod = (isLoading: boolean, method?: RequestMethods) => {
		setLoading(isLoading);
		method === 'GET' && setGetLoading(isLoading);
		method === 'PUT' && setPutLoading(isLoading);
		method === 'POST' && setPostLoading(isLoading);
		method === 'DELETE' && setDeleteLoading(isLoading);
	};

	const fetchFactory = useCallback(
		(method: RequestMethods) => async (options?: RequestOptions) => {
			setData(null);
			setError(false);
			setLoadingByMethod(true, method);

			let json;
			const mergedOptions = { ...ReqInit, ...fetchOptions, ...options } as RequestOptions;
			const url = mergedOptions?.url || fetchUrl;
			if (!url) return;

			try {
				json = await authFetch(url, {
					...mergedOptions,
					method: method,
					token: user?.token,
				});
				setData(json);
				setLoadingByMethod(false, method);
				if (json?.error) throw new Error(`${json.error}`);
			} catch (e) {
				setError(e);
				setData(null);
				setLoadingByMethod(false, method);
				throw e;
			}
			return json;
		},
		[user?.token, fetchOptions]
	);

	return {
		data,
		error,
		loading,
		getLoading,
		putLoading,
		postLoading,
		deleteLoading,
		Get: fetchFactory('GET'),
		Put: fetchFactory('PUT'),
		Post: fetchFactory('POST'),
		Delete: fetchFactory('DELETE'),
	};
};

export { authFetch, objectToFormData };

export default useFetch;
