import { createAction } from '@reduxjs/toolkit';


export const setDate = createAction<
    {
        startDate: string,
        endDate: string,
        weekConfig: { week1: { startDate: string, endDate: string }, week2: { startDate: string, endDate: string } },
    }>('setDate');


export const setRange = createAction<
    {
        startDate: string,
        endDate: string,
    }>('setRange');

export const setPayrollWeek = createAction<
    {
        startDate: string,
        endDate: string,
    }>('setPayrollWeek');


export const clearDate = createAction('clearDate');