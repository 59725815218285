import '../Assets/less/rsuite.config.less';
import '../Assets/scss/base/export.scss';
import '../Assets/scss/base/RSuite.scss';
import '../Assets/scss/App.scss';
import '../Assets/scss/base/tailwind.scss';
import RoutesRenderer from './Routes/RouteRenderer';
import useCacheInvalidator from '../Tools/Hooks/useCacheInvalidator';

const App = () => {
	useCacheInvalidator();
	return <RoutesRenderer />;
};

export default App;
